import React from "react";
import styles from "./ThankYou.module.css";
import { ButtonNext } from "../ButtonNext/ButtonNext";
import imgDone from "../../assets/images/png/booking.png";

export const ThankYou = props => {
  const name = props.name.split(" ")[0];
  const code = props.country === "Colombia" ? "57" : "52";
  const number = props.country === "Colombia" ? "305 252 1482" : "55 8414 8295";

  const handleWhats = () => {
    console.log("object");
    window.open(
      `https://api.whatsapp.com/send?phone=${code}${number}`,
      "_blank"
    );
  };

  return (
    <div className={styles.Cotnainer}>
      <div className={styles.ImageContainer}>
        <img
          src={imgDone}
          alt="Tratamiento pagado moons"
          className={styles.Image}
        />
      </div>
      <div className={styles.Title}>
        ¡Cambiemos tu sonrisa desde tu casa, {name}!
      </div>

      <div className={styles.Text}>
        Para comenzar, mándanos un WhatsApp para que te contemos todos los
        detalles del kit.
      </div>
      <div className={styles.ButtonContainer}>
        <ButtonNext
          text={"ESCRÍBENOS POR WHATSAPP"}
          event={() => handleWhats()}
        />
      </div>
    </div>
  );
};
