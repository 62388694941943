import React from "react";
import styles from "./Moldes.module.css";
import { ButtonNext } from "../ButtonNext/ButtonNext";
import imgMoldes from "../../assets/images/png/impression_kit.png";

export const Moldes = props => {
  window.dataLayer.push({ event: "moonskit-moldes" });
  return (
    <>
      <iframe
        src={`https://player.vimeo.com/video/398703342?byline=0&badge=0&portrait=0&title=0`}
        allowFullScreen
        title="Kit moons"
        scrolling="no"
        allow="encrypted-media"
        className={styles.IframeClass}
      ></iframe>
      <div className={styles.ContainerMoldesKit}>
        <div className={styles.title}>Kit de impresiones en casa</div>
        <br></br>
        <div className={styles.Text}>
          ¡Todo lo que necesitas para hacer tus impresiones dentales en minutos!
          Con ellas, tu Ortodoncista moons crea un modelo digital en 3D para
          diseñar tu tratamiento a la medida.{" "}
        </div>
        <div className={styles.ModelImg}>
          <img
            src={imgMoldes}
            className={styles.ImgMoldes}
            alt="Moldes Moons"
          />
        </div>
        <div className={styles.title}>Contiene:</div>
        <div className={styles.Text}>
          <ul className={styles.LeftText}>
            <li type="disc">4 cucharillas (inferiores y superiores)</li>
            <li type="disc">2 retractores de plástico </li>
            <li type="disc">Guía de envío pre-pagada </li>
            <li type="disc">
              Plan de Tratamiento hecho por tu Ortodoncista moons{" "}
            </li>
          </ul>
        </div>
        <br />
        <div className={styles.title}>¿Cómo funciona?</div>
        <div className={styles.Text}>
          <ol className={styles.LeftText}>
            <li>Recibe tu kit</li>
            <li>
              Sigue las instrucciones para crear los moldes de tus dientes de
              arriba y abajo
            </li>
            <li>Regresa la caja con tus moldes usando la guía pre-pagada</li>
          </ol>
        </div>
        <ButtonNext
          text={"QUIERO MI KIT"}
          event={() => props.handleChekout()}
        />
        <div className={styles.Space} />
      </div>
    </>
  );
};
