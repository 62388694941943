import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";

import { getCountry } from "./util/Utils";
import Test from "./Components/Test";
import { getUTMs } from "./util/UTM";

function App() {
  const country = getCountry();
  getUTMs();
  console.log(country);
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <Test {...props} country={country} />}
        />
        <Route path="/" render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
