import React, { Component } from "react";
import styles from "./SocialMedia.module.css";
import share from "../../assets/images/png/share.png";
import facebook from "../../assets/images/png/facebook.png";
import twitter from "../../assets/images/png/twitter.png";

export default class SocialMedia extends Component {
  componentDidMount() {}

  handleCopy = () => {
    const copyText = document.getElementById("copy");
    copyText.classList.toggle(styles.hide);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    copyText.classList.toggle(styles.hide);
    alert("Copied the text: " + copyText.value);
  };

  render() {
    const country = this.props.country === "Colombia" ? "co" : "mx";
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          ¿Sabes de alguien que necesita moons?
          <br />
          Compártele este test y hazle un favor:
        </div>
        <div className={styles.urlContainer}>
          <div id="social-media" className={styles.media}>
            <a
              href={`https://twitter.com/share?url=https://mymoons.${country}/soy-candidato&amp;text=Moons%20es%20asombroso&amp;hashtags=moons`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="Twitter" width="32" height="32" />
            </a>
            <a
              href={`http://www.facebook.com/sharer.php?u=https://mymoons.${country}/soy-candidato`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" width="32" height="32" />
            </a>
            <input
              className={styles.hide}
              defaultValue={`https://mymoons.${country}/soy-candidato`}
              id="copy"
            />
            <img
              src={share}
              alt="share"
              width="32"
              height="32"
              className={styles.button}
              onClick={this.handleCopy}
            />
          </div>
        </div>
      </div>
    );
  }
}
