import React from "react";
import styles from "./Layout.module.css";
import logo from "../../assets/images/svg/logo.svg";

export const Layout = props => {
  const url =
    props.country === "Colombia" ? "https://mymoons.co" : "https://mymoons.mx";
  return (
    <>
      <div className={styles.Header}>
        <div className={styles.Logo}>
          <a href={url} className={styles.LinkHome}>
            <img src={logo} alt="Logo moons" />
          </a>
        </div>
      </div>
      <div className={styles.Container}>{props.children}</div>
      {props.country === "México" && (
        <div className={styles.FooterContainer}>
          <div className={styles.Footer}>
            © Moons, 2020. Todos los derechos reservados. - Colima 220, Roma
            Norte, 06700, CDMX, México PERMISO COFEPRIS 203300201A0333 Dr. Yair
            Zyman Fe, Cédula de licenciatura: 27966448 de la Universidad
            Tecnológica de México, Cédula de especialización: 8693572 de The
            University of Pittsburgh, Estados Unidos de América.
          </div>
        </div>
      )}
    </>
  );
};
