import React from "react";
import styles from "./ButtonNext.module.css";

export const ButtonNext = props => {
  return (
    <div className={styles.nextContainer}>
      <div className={styles.buttonNext} onClick={props.event}>
        {props.text}
      </div>
    </div>
  );
};
