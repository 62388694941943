export const PARSE_URL = "https://parseapi.back4app.com";
export const PARSE_APP = "be5vX46tPuX4OrqmbOnO7vgueY40Z5juUs98xPDM";
export const PARSE_KEY = "qjlEv4ogp9SE0Uy1e723bKkzj1AOVzNLQcoUDQnf";

export const ACUITY_API_GATEWAY =
  "https://81btxt1nr0.execute-api.eu-west-1.amazonaws.com/acuity/";

export const BLACKBOX_URL = "https://production.moons.ninja/crm/blackbox/";
//export const BLACKBOX_URL = "https://dev.moons.rocks/crm/blackbox/";

export const DAYS = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado"
];
export const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];
