import React, { Component } from "react";
import styles from "./Final.module.css";
import imgMexico from "../../assets/images/png/BackMexico2.png";
import imgColombia from "../../assets/images/png/BackColombia2.png";
import check from "../../assets/images/svg/check.svg";
import { ButtonNext } from "../ButtonNext/ButtonNext";
import { Agenda } from "../Agenda/Agenda";
import { Moldes } from "../Moldes/Moldes";

export default class Final extends Component {
  constructor() {
    super();
    this.state = {
      step: 0,
      enable: false,
    };
  }

  componentDidMount() {
    this.props.save();
    const img = document.createElement("img");
    img.setAttribute(
      "src",
      "https://www.facebook.com/tr?id=427318704548057&ev=Lead&noscript=1"
    );
    document.getElementById("final").appendChild(img);
  }

  selectHandler = (id) => {
    document.getElementById(id).classList.remove(styles.border);
    document.getElementById(id).classList.add(styles.borderSelected);
    document.getElementById(id + "-check").classList.remove(styles.hide);
    if (id === "cita") {
      if (this.props.country === "Colombia") {
        window.open("https://mymoons.co/ubicaciones", "_blank");
      } else {
        document.getElementById("molde").classList.add(styles.border);
        document
          .getElementById("molde")
          .classList.remove(styles.borderSelected);
        document.getElementById("molde-check").classList.add(styles.hide);
      }
    } else {
      document.getElementById("cita").classList.add(styles.border);
      document.getElementById("cita").classList.remove(styles.borderSelected);
      document.getElementById("cita-check").classList.add(styles.hide);
    }
    this.setState({ enable: true });
  };

  handleNext = () => {
    const select = document.getElementsByClassName(styles.borderSelected)[0].id;
    if (select === "cita") {
      this.setState({ step: "visita" });
    } else {
      this.setState({ step: "molde" });
    }
  };

  handleKit = () => {
    this.setState({ step: "molde" });
  };

  handleChekout = () => this.props.nextStep();

  render() {
    window.scrollTo(0, 0);

    const name = this.props.name.split(" ");

    return (
      <div id="final" className={styles.container}>
        {this.props.country === "México" && this.state.step !== "molde" && (
          <img src={imgMexico} className={styles.imgTop} alt="moons-top" />
        )}
        {this.props.country === "Colombia" && this.state.step !== "molde" && (
          <img src={imgColombia} className={styles.imgTop} alt="moons-top" />
        )}

        {this.state.step === 0 && (
          <div className={styles.body}>
            <div className={styles.title}>
              ¡Felicidades, {name[0]}! <br />
              Parece que sí eres candidato 💛
            </div>
            <div className={styles.OptionsCotnainer}>
              <div
                id={"cita"}
                className={styles.border}
                onClick={() => this.selectHandler("cita")}
              >
                <div
                  id="cita-check"
                  className={`${styles.leftIcon} ${styles.hide}`}
                >
                  <img
                    src={check}
                    size={12}
                    color={"white"}
                    alt="check moons"
                  />
                </div>
                <div className={styles.contentSize}>Agendar una cita</div>
              </div>

              <div
                id={"molde"}
                className={styles.border}
                onClick={() => this.selectHandler("molde")}
              >
                <div
                  id="molde-check"
                  className={`${styles.leftIcon} ${styles.hide}`}
                >
                  <img
                    src={check}
                    size={12}
                    color={"white"}
                    alt="check moons"
                  />
                </div>
                <div className={styles.contentSize}>Moldes en casa</div>
              </div>
            </div>
            {this.state.enable && (
              <ButtonNext text={"SIGUIENTE"} event={this.handleNext} />
            )}
            <div className={styles.Space} />
          </div>
        )}

        {this.state.step === "visita" && <Agenda handleKit={this.handleKit} />}

        {this.state.step === "molde" && (
          <Moldes handleChekout={this.handleChekout} />
        )}
      </div>
    );
  }
}
