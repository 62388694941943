import React, { Component } from "react";
import styles from "./Question7.module.css";
import Form from "../Form/Form";

export default class Question7 extends Component {
  handleForm = data => this.props.formHandle(data);

  componentDidMount() {
    /* window.criteo_q = window.criteo_q || [];
    var deviceType = /iPad/.test(navigator.userAgent)
      ? "t"
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
          navigator.userAgent
        )
      ? "m"
      : "d";
    if (this.props.country === "mx") {
      window.criteo_q.push(
        { event: "setAccount", account: 67586 }, // You should never update this line
        { event: "setEmail", email: "" }, // Can be an empty string
        { event: "setSiteType", type: deviceType },
        { event: "viewBasket", item: [{ id: "1", price: 1, quantity: 1 }] }
      );
    }
    if (this.props.country === "co") {
      window.criteo_q.push(
        { event: "setAccount", account: 67796 }, // You should never update this line
        { event: "setEmail", email: "" }, // Can be an empty string
        { event: "setSiteType", type: deviceType },
        { event: "viewBasket", item: [{ id: "1", price: 1, quantity: 1 }] }
      );
    } */
  }

  render() {
    window.scrollTo(0, 0);
    return (
      <div className={`${styles.containerFlex} ${styles.heightContainer}`}>
        <div>
          <p className={styles.title}>¿A dónde enviamos tus resultados?</p>
          <Form formHandle={this.handleForm} />
        </div>
      </div>
    );
  }
}
