import React from "react";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../CheckoutForm/CheckoutForm";
import styles from "./Checkout.module.css";
import imgKit from "../../assets/images/png/impression_kit.png";
const stripePromise = loadStripe("pk_live_nfv1VCqxz7LBrs69XmrqBzuV00TM2owSNd");

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.productKeyName = this.props.productKeyName;

    console.log({ product: this.productKeyName });
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    const img = document.createElement("img");
    img.setAttribute(
      "src",
      "https://www.facebook.com/tr?id=427318704548057&ev=AddToCart&noscript=1"
    );
    document.getElementById("checkout").appendChild(img);
  }

  render() {
    return (
      <div className={styles.FormContainer} id="checkout">
        <div className={styles.Kit}>
          <div className={styles.ImageContainer}>
            <img src={imgKit} alt="Moldes Moons" className={styles.Image} />
          </div>
          {this.props.country === "Colombia" && (
            <div className={styles.Title}>
              Kit de impresiones en casa <del>$90.000</del> COP $60.000 COP
            </div>
          )}
          {this.props.country === "México" && (
            <div className={styles.Title}>
              Kit de impresiones en casa <del>$2,000</del> $1,000
            </div>
          )}
          <div className={styles.Title}>Incluye:</div>
          <div className={styles.Text}>
            <ul className={styles.LeftText}>
              <li type="disc">4 cucharillas (inferiores y superiores)</li>
              <li type="disc">2 retractores de plástico </li>
              <li type="disc">Guía de envío pre-pagada </li>
              <li type="disc">
                Plan de Tratamiento hecho por tu Ortodoncista moons{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.Form}>
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <CheckoutForm
                  country={this.props.country}
                  productKeyName={this.productKeyName}
                  elements={elements}
                  stripe={stripe}
                  name={this.props.name}
                  email={this.props.email}
                  phone={this.props.phone}
                  paymentSuccess={this.props.nextStep}
                />
              )}
            </ElementsConsumer>
          </Elements>
        </div>
        <div className={styles.Space} />
        <br></br>
        <br></br>
        <br></br>
      </div>
    );
  }
}

export default Checkout;
