import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import style from "./CheckoutForm.module.css";
import CardSection from "../CardSection/CardSection";
import { LoaderSection } from "../LoaderSection/LoaderSection";

export default class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: this.props.phone,
      name: this.props.name,
      email: this.props.email,
      calle: "",
      numExt: "",
      numInt: "",
      alcaldia: "",
      colonia: "",
      errorName: false,
      errorEmail: false,
      errorNumber: false,
      errorCalle: false,
      errorNumExt: false,
      errorNumInt: false,
      errorColonia: false,
      errorAlcaldia: false,
      error: false,
      errorData: null,
      loader: false,
    };

    this.errorTextName = "";
    this.errorTextEmail = "";
    this.errorTextNumber = "";

    this.productKeyName = this.props.productKeyName;
    console.log(this.productKeyName);
    this.onChangeInputNumber = this.onChangeInputNumber.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Valida el nombre
   */
  validateName = async (name) => {
    if (name === null || name === undefined || name === "") {
      this.errorTextName = "El nombre no puede estar vacio!";
      this.setState({ errorName: true });
      return false;
    }
    if (name.split(" ").length <= 1) {
      this.errorTextName = "Necesitamos al menos un apellido";
      this.setState({ errorName: true });
      return false;
    }

    this.setState({ errorName: false });
    return true;
  };

  /**
   * Valida el correo
   */
  validateEmail = async (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email.toString().toLowerCase())) {
      this.errorTextEmail = "Error en el correo, verificalo!";
      this.setState({ errorEmail: true });
      return false;
    }

    this.setState({ errorEmail: false });
    return true;
  };

  /**
   * Valida el número
   */
  validatePhone = async (phone) => {
    if (phone.toString().length < 10) {
      this.errorTextNumber = "El número debe de tener 10 digitos.";
      this.setState({ errorNumber: true });
      return false;
    }

    this.setState({ errorNumber: false });
    return true;
  };

  validateInputCalle = (value) => {
    if (value === "") {
      this.setState({ errorCalle: true });
      return false;
    }
    this.setState({ errorCalle: false });
    return true;
  };

  validateInputNumExt = (value) => {
    if (value === "") {
      this.setState({ errorNumExt: true });
      return false;
    }
    this.setState({ errorNumExt: false });
    return true;
  };

  validateInputColonia = (value) => {
    if (value === "") {
      this.setState({ errorColonia: true });
      return false;
    }
    this.setState({ errorColonia: false });
    return true;
  };

  validateInputAlcaldia = (value) => {
    if (value === "") {
      this.setState({ errorAlcaldia: true });
      return false;
    }
    this.setState({ errorAlcaldia: false });
    return true;
  };

  onChangeInputNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ number: e.target.value });
    }
  };
  onChangeInputName = (e) => {
    this.setState({ name: e.target.value });
  };

  onChangeInputEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  onChangeInput = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  /**
   * Maneja la funcionalidad al momento de apretar el boto de pago
   */
  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    document.getElementById("form").classList.add(style.DisplayNone);
    document.getElementById("loader").classList.remove(style.DisplayNone);

    const { stripe, elements } = this.props;

    let { name, email, number, colonia, calle, numExt, alcaldia } = this.state;

    console.log({ name, email, number });

    let validName = await this.validateName(name);
    let validEmail = await this.validateEmail(email);
    let validNumber = await this.validatePhone(number);
    let validColonia = await this.validateInputColonia(colonia);
    let validNumExt = await this.validateInputNumExt(numExt);
    let validCalle = await this.validateInputCalle(calle);
    let validAlcaldia = await this.validateInputAlcaldia(alcaldia);

    console.log(validAlcaldia);

    if (this.props.country === "México") {
      if (
        !validName ||
        !validEmail ||
        !validNumber ||
        !validColonia ||
        !validCalle ||
        !validNumExt ||
        !validAlcaldia
      ) {
        document.getElementById("form").classList.remove(style.DisplayNone);
        document.getElementById("loader").classList.add(style.DisplayNone);
        return;
      }
    }

    if (this.props.country === "Colombia") {
      if (
        !validName ||
        !validEmail ||
        !validNumber ||
        !validColonia ||
        !validCalle ||
        !validAlcaldia
      ) {
        document.getElementById("form").classList.remove(style.DisplayNone);
        document.getElementById("loader").classList.add(style.DisplayNone);
        return;
      }
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    console.log(stripe);
    console.log(elements);
    console.log(elements.getElement(CardElement));
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: name,
        email: email,
        phone: number,
      },
    });
    console.log(result);
    if (result.error) {
      console.log(result.error);
      this.setState({ errorData: result.error });
    } else {
      this.setState({ loader: true });
    }

    this.stripePaymentMethodHandler(result, this.productKeyName);
  };

  /**
   * Maneja la funcionalidad de stripe para recibir el intento de pago.
   * hace una llamada a la lambda para las funcionalidades de stripe en el back
   */
  stripePaymentMethodHandler = async (result, productKeyName) => {
    if (result.error) {
      this.setState({ paymentSuccess: false, loader: false, error: true });
    } else {
      try {
        let paymentMethod = result.paymentMethod;

        const response = await axios.post(
          //"https://dev.moons.rocks/stripe/payment",
          "https://production.moons.ninja/stripe/payment",
          { paymentId: paymentMethod.id, keyName: this.productKeyName }
        );
        console.log(response);
        let data = response.data;
        console.log("dwadwdadwadwqdwa");
        if (data.success) {
          this.setState({
            paymentSuccess: true,
            error: false,
            loader: false,
          });

          const img = document.createElement("img");
          img.setAttribute(
            "src",
            "https://www.facebook.com/tr?id=427318704548057&ev=Purchase&noscript=1"
          );
          document.getElementById("payment-success").appendChild(img);
          this.props.paymentSuccess();
        } else {
          this.setState({ paymentSuccess: false, loader: false, error: true });
        }
      } catch (err) {
        this.setState({ error: true, loader: false });
        console.log(err.message);
        this.setState({ paymentSuccess: false, loader: false, error: true });
      }
    }
  };

  render() {
    let {
      errorName,
      errorNumber,
      errorEmail,
      errorCalle,
      errorNumExt,
      errorColonia,
      errorAlcaldia,
    } = this.state;
    let labelErrorName = null;
    let labelErrorEmail = null;
    let labelErrorNumber = null;
    let labelErrorColonia = null;
    let labelErrorCalle = null;
    let labelErrorNumExt = null;
    let labelErrorNumInt = null;
    let labelErrorAlcaldia = null;

    if (errorName) {
      labelErrorName = (
        <label className={style.ErrorMessage}>{this.errorTextName}</label>
      );
    }
    if (errorEmail) {
      labelErrorEmail = (
        <label className={style.ErrorMessage}>{this.errorTextEmail}</label>
      );
    }
    if (errorNumber) {
      labelErrorNumber = (
        <label className={style.ErrorMessage}>{this.errorTextNumber}</label>
      );
    }

    if (errorCalle) {
      labelErrorCalle = (
        <label className={style.ErrorMessage}>Ingresa una Calle</label>
      );
    }

    if (this.props.country === "México") {
      if (errorColonia) {
        labelErrorColonia = (
          <label className={style.ErrorMessage}>Ingresa una Colonia</label>
        );
      }

      if (errorNumExt) {
        labelErrorNumExt = (
          <label className={style.ErrorMessage}>
            Ingresa un número Exterior
          </label>
        );
      }

      if (errorAlcaldia) {
        labelErrorAlcaldia = (
          <label className={style.ErrorMessage}>Ingresa una Alcaldía</label>
        );
      }
    }

    if (this.props.country === "Colombia") {
      if (errorColonia) {
        labelErrorColonia = (
          <label className={style.ErrorMessage}>Ingresa una Colonia</label>
        );
      }

      if (errorAlcaldia) {
        labelErrorAlcaldia = (
          <label className={style.ErrorMessage}>Ingresa una Alcaldía</label>
        );
      }
    }

    if (this.state.paymentSuccess) {
      return (
        <div id="payment-success">
          <h1>Felicidades tu pago ha sido aceptado</h1>
        </div>
      );
    }
    return this.state.error === true ? (
      <div>
        <h1>
          {this.state.errorData !== null
            ? this.state.errorData.message
            : "Surgio un problema con el servidor"}{" "}
          Vuelve a intentarlo
        </h1>
        <button
          onClick={(e) => this.setState({ error: false })}
          className={`${style.actionButton} ${style.payButton} ${style.payButtonBig}`}
        >
          Volver a intentar
        </button>
      </div>
    ) : (
      <>
        <div
          className={`${style.FormContainer} ${style.DisplayNone}`}
          id="loader"
        >
          <LoaderSection />
        </div>
        <form
          onSubmit={this.handleSubmit}
          className={style.FormContainer}
          id="form"
        >
          {this.props.country === "México" && (
            <div className={style.Title}>Por el momento solo CDMX</div>
          )}
          {this.props.country === "Colombia" && (
            <div className={style.Title}>Envios a toda la nación</div>
          )}
          <div className={`${style.inputContainer} ${style.DisplayNone}`}>
            <label className={style.inputLabel} htmlFor="inputName">
              Nombre:
            </label>
            <input
              onChange={this.onChangeInputName}
              value={this.state.name}
              type="text"
              className="form-control"
              id="inputName"
              placeholder="Escribe tu nombre"
            ></input>
            {labelErrorName}
          </div>
          <div className={`${style.inputContainer} ${style.DisplayNone}`}>
            <label className={style.inputLabel} htmlFor="inputEmail">
              Correo:
            </label>
            <input
              onChange={this.onChangeInputEmail}
              value={this.state.email}
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder="Escribe tu correo"
            ></input>
            {labelErrorEmail}
          </div>
          <div className={`${style.inputContainer} ${style.DisplayNone}`}>
            <label className={style.inputLabel} htmlFor="inputNumber">
              Numero:
            </label>
            <input
              type="text"
              onChange={this.onChangeInputNumber}
              value={this.state.number}
              className="form-control"
              id="inputNumber"
              placeholder="Escribe tu número celular"
            ></input>
            {labelErrorNumber}
          </div>
          <div className={style.inputContainer}>
            <label className={style.inputLabel} htmlFor="calle">
              Calle:
            </label>
            <input
              onChange={this.onChangeInput}
              value={this.state.calle}
              type="text"
              className="form-control"
              id="calle"
              placeholder="Escribe tu calle"
            ></input>
            {labelErrorCalle}
          </div>
          {this.props.country === "México" && (
            <div className={style.AllWidth}>
              <div className={style.inputContainer}>
                <label className={style.inputLabel} htmlFor="numExt">
                  Número Exterior:
                </label>
                <input
                  onChange={this.onChangeInput}
                  value={this.state.numExt}
                  type="text"
                  className="form-control"
                  id="numExt"
                  placeholder="Escribe tu número Exterior"
                ></input>
                {labelErrorNumExt}
              </div>
              <div className={style.inputContainer}>
                <label className={style.inputLabel} htmlFor="numInt">
                  Número Interior:
                </label>
                <input
                  onChange={this.onChangeInput}
                  value={this.state.numInt}
                  type="text"
                  className="form-control"
                  id="numInt"
                  placeholder="Escribe tu número Interior"
                ></input>
                {labelErrorNumInt}
              </div>
              <div className={style.inputContainer}>
                <label className={style.inputLabel} htmlFor="colonia">
                  Colonia:
                </label>
                <input
                  onChange={this.onChangeInput}
                  value={this.state.colonia}
                  type="text"
                  className="form-control"
                  id="colonia"
                  placeholder="Escribe tu Colonia"
                ></input>
                {labelErrorColonia}
              </div>
              <div className={style.inputContainer}>
                <label className={style.inputLabel} htmlFor="alcaldia">
                  Alcaldía:
                </label>
                <input
                  onChange={this.onChangeInput}
                  value={this.state.alcaldia}
                  type="text"
                  className="form-control"
                  id="alcaldia"
                  placeholder="Escribe tu Alcaldia"
                ></input>
                {labelErrorAlcaldia}
              </div>
            </div>
          )}
          {this.props.country === "Colombia" && (
            <div className={style.AllWidth}>
              <div className={style.inputContainer}>
                <label className={style.inputLabel} htmlFor="colonia">
                  Barrio:
                </label>
                <input
                  onChange={this.onChangeInput}
                  value={this.state.colonia}
                  type="text"
                  className="form-control"
                  id="colonia"
                  placeholder="Escribe tu Barrio"
                ></input>
                {labelErrorColonia}
              </div>
              <div className={style.inputContainer}>
                <label className={style.inputLabel} htmlFor="alcaldia">
                  Localidad:
                </label>
                <input
                  onChange={this.onChangeInput}
                  value={this.state.alcaldia}
                  type="text"
                  className="form-control"
                  id="alcaldia"
                  placeholder="Escribe tu Localidad"
                ></input>
                {labelErrorAlcaldia}
              </div>
            </div>
          )}
          <div className={style.inputContainer}>
            <label className={style.inputLabel} htmlFor="card">
              Tarjeta de credito/debito:
            </label>
            <CardSection />
          </div>
          <button
            type="submit"
            className={`${style.actionButton} ${style.payButton} ${style.payButtonBig}`}
          >
            Pagar
          </button>
        </form>
      </>
    );
  }
}
