import { BLACKBOX_URL } from "../util/Constants";

const callApi = async (options) => {
  const response = await fetch(BLACKBOX_URL, options);
  return await response.json();
};

export const apiBlackBox = {
  Appoinment(name, phone, email, utcs, referralId) {
    const request = JSON.stringify({
      email: email,
      phone: phone,
      deal: {
        Patient_Name: name,
        Source: utcs.utm_source,
        Campaign: utcs.utm_campaign,
        Appointment_Source: utcs.appointment,
        Ad_Group: utcs.utm_medium,
        Creative_Name: utcs.creative,
        Referral: referralId,
        Referral_Amount: 2500,
        Appointment_Amount: 500,
        Referral_Active: true,
        Referral_Payment: "No Payment",
      },
      tokenName: "appointments",
    });
    return callApi({
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: request,
    });
  },
  UpdateAppointmentSource(phone, email, appointment) {
    return callApi({
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: email,
        phone: phone,
        deal: {
          Appointment_Source: appointment,
        },
      }),
    });
  },
  Patient(phone, email, name, utcs, origin, country) {
    return callApi({
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: email,
        phone: phone,
        deal: {
          Patient_Name: name,
          Source: utcs.utm_source,
          Campaign: utcs.utm_campaign,
          Ad_Group: utcs.utm_medium,
          Creative_Name: utcs.creative,
          Deal_Content: utcs.content,
          Deal_Origin: origin,
          Country_Ops: country,
        },
      }),
    });
  },
};
