import React from "react";
import styles from "./Agenda.module.css";
import { ButtonNext } from "../ButtonNext/ButtonNext";

export const Agenda = props => {
  window.dataLayer.push({ event: "moonskit-agendar" });
  return (
    <div className={styles.ContainerVisita}>
      <div className={styles.title}>Oh, no...</div>
      <div className={styles.Text}>
        Nuestros centros están cerrados temporalmente por medidas de precaución
        ante la contingencia por COVID-19.
      </div>
      <br />
      <div className={styles.Text}>
        ¿Quieres empezar a cambiar tu sonrisa desde casa? ¡Pide tu kit de
        impresiones a domicilio!
      </div>
      <br />
      <br />
      <br />
      <br />
      <ButtonNext text={"VER KIT"} event={() => props.handleKit()} />
      <div className={styles.Space} />
    </div>
  );
};
