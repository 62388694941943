import Parse from "parse/node";
import { PARSE_URL, PARSE_APP, PARSE_KEY } from "../util/Constants";
import { Component } from "react";
import { CONDITIONS, REAZONS } from "../Components/Constants";

export default class ApiParse extends Component {
  constructor() {
    super();
    Parse.serverURL = PARSE_URL;
    Parse.initialize(PARSE_APP, PARSE_KEY);
  }

  /* Get branch by centro name */
  getBranchByCentro = async centro => {
    const Branch = Parse.Object.extend("Branch");
    const query = new Parse.Query(Branch);
    query.equalTo("centro", centro);
    const response = await query.find().then(
      results => JSON.stringify(results),
      error => {
        console.log("Error while fetching ParseObjects", error);
      }
    );
    return JSON.parse(response);
  };

  /* Get branches for Acuity */
  GetBranches = async country => {
    const Branch = Parse.Object.extend("Branch");
    const query = new Parse.Query(Branch);
    query.equalTo("country", country);
    const response = await query.find().then(
      results => JSON.stringify(results),
      error => {
        console.error("Error while fetching ParseObjects", error);
      }
    );
    return JSON.parse(response);
  };

  saveCandidate = answers => {
    const Candidate = Parse.Object.extend("Candidate");
    const myNewObject = new Candidate();
    let isCandidate = false;

    const answer2 = [];
    if (answers[1].encimados) answer2.push("encimados");
    if (answers[1].enderezar) answer2.push("enderezar");
    if (answers[1].mordida) answer2.push("mordida");
    if (answers[1].separados) answer2.push("separados");

    let cont = 0;
    const answer5 = [];
    answers[4].forEach(ans => {
      if (ans) answer5.push(CONDITIONS[cont].sku);
      cont++;
    });
    cont = 0;
    let answer6;
    if (answers[5] === undefined) {
      answer6 = null;
    } else {
      answers[5].forEach(ans => {
        if (ans) answer6 = REAZONS[cont].sku;
        cont++;
      });
      isCandidate = true;
    }

    myNewObject.set("isAdult", answers[2]);
    myNewObject.set("smileType", answer2);
    myNewObject.set("brackets", answers[3]);
    myNewObject.set("conditions", answer5);
    myNewObject.set("reazons", answer6);
    myNewObject.set("isCandidate", isCandidate);
    myNewObject.set("name", answers[0]);
    myNewObject.set("email", answers[6].email);
    myNewObject.set("phone", answers[6].phone);
    myNewObject.set("country", answers[7]);

    myNewObject.save().then(
      result => {
        if (typeof document !== "undefined") return result;
      },
      error => {
        if (typeof document !== "undefined")
          console.error("Error while creating Candidate: ", error);
      }
    );
  };

  saveCP = (cp, type) => {
    const CPTest = Parse.Object.extend("CPTest");
    const myNewObject = new CPTest();

    myNewObject.set("cp", cp);
    myNewObject.set("type", type);
    myNewObject.save().then(
      result => {
        console.log("CPTest created", result);
      },
      error => {
        console.error("Error while creating CPTest: ", error);
      }
    );
  };
}
