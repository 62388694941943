/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./CardSection.css";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      width: "80px",
      display: "block",
      height: "calc(2.25rem + 2px)",
      padding: "0.375rem 0.75rem",
      fontWeight: "400",
      lineHeight: "1.5",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      boxSizing: "border-box",
      /* margin-bottom: 1rem; */
      marginBottom: "10px",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

function CardSection() {
  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
}

export default CardSection;
