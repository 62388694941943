import React, { Component } from "react";
import NoCandidate from "./NoCandidate/NoCandidate";
import Final from "./Final/Final";
import { NoCandidateLanding } from "./NoCandidateFinal/NoCandidateLanding";
import Question1 from "./Question1/Question1";
import Question2 from "./Question2/Question2";
import Question3 from "./Question3/Question3";
import Question4 from "./Question4/Question4";
import Question56 from "./Question5-6/Question5-6";
import Question7 from "./Question7/Question7";
import LoaderFullScreen from "./LoaderFullScreen/LoaderFullScreen";
import ApiParse from "../Apis/Parse";
import { apiBlackBox } from "../Apis/BlackBox";
import { Layout } from "./Layout/Layout";
import Checkout from "./Checkout/Checkout";
import { ThankYou } from "./ThankYou/ThankYou";
import { getUtcs } from "../util/Utils";

export default class Test extends Component {
  constructor() {
    super();
    this.state = {
      currentStep: 0,
      answers: [],
      noCandidate: false,
      noCadidateStep: 0,
      message: undefined,
    };
  }

  startHandle = () => {
    const currentStep = this.state.currentStep + 1;
    this.setState({ currentStep });
  };

  saveInfo = (res) => {
    const currentStep = this.state.currentStep + 1;
    const answers = this.state.answers;
    answers.push(res);
    this.setState({ currentStep, answers });
  };

  saveNoCandidate = (res) => {
    const answers = this.state.answers;
    answers[5] = res;
    this.setState({ answers, currentStep: 11 });
    this.saveData();
  };

  question3Handler = (res) => {
    if (res) {
      this.saveInfo(res);
    } else {
      const answers = this.state.answers;
      answers.push(res);
      this.setState({ noCandidate: true, message: 0 });
    }
  };

  question = (res) => this.saveInfo(res);

  question4Handler = (res) => {
    if (res[5]) this.setState({ noCandidate: true, message: 3 });
    this.saveInfo(res);
  };

  saveData = () => {
    const apiParse = new ApiParse();
    const country = this.props.country;
    const answers = this.state.answers;
    answers.push(country);
    apiParse.saveCandidate(answers);

    const utcs = getUtcs();
    apiBlackBox.Patient(
      this.state.answers[6].phone,
      this.state.answers[6].email,
      this.state.answers[0],
      utcs,
      "TestKitMoons",
      country
    );
  };

  render() {
    if (this.state.currentStep === 11) {
      return (
        <Layout country={this.props.country}>
          <NoCandidateLanding name={this.state.answers[0]} />
        </Layout>
      );
    }

    if (this.state.noCandidate) {
      return (
        <Layout country={this.props.country}>
          <NoCandidate
            message={this.state.message}
            country={this.props.country}
            formHandle={this.saveNoCandidate}
          />
        </Layout>
      );
    }

    const product =
      this.props.country === "Colombia" ? "moons-kit-co" : "moons-kit-mx";

    return (
      <Layout country={this.props.country}>
        {this.state.currentStep === 0 && (
          <Question1
            question1Handler={this.question}
            country={this.props.country}
          />
        )}
        {this.state.currentStep === 1 && (
          <Question2
            question2Handler={this.question}
            name={this.state.answers[0]}
          />
        )}
        {this.state.currentStep === 2 && (
          <Question3 question3Handler={this.question3Handler} />
        )}
        {this.state.currentStep === 3 && (
          <Question4
            question4Handler={this.question}
            name={this.state.answers[0]}
          />
        )}
        {this.state.currentStep === 4 && (
          <Question56
            question={4}
            question4Handler={this.question4Handler}
            name={this.state.answers[0]}
          />
        )}
        {this.state.currentStep === 5 && (
          <Question56
            question={5}
            question5Handler={this.question}
            name={this.state.answers[0]}
          />
        )}
        {this.state.currentStep === 6 && (
          <Question7 formHandle={this.question} country={this.props.country} />
        )}
        {this.state.currentStep === 7 && (
          <LoaderFullScreen
            timer={this.startHandle}
            message={"Estamos revisando tus respuestas…"}
          />
        )}
        {this.state.currentStep === 8 && (
          <Final
            name={this.state.answers[0]}
            email={this.state.answers[6].email}
            phone={this.state.answers[6].phone}
            save={this.saveData}
            country={this.props.country}
            createAppoinment={this.startHandle}
            nextStep={this.startHandle}
          />
        )}
        {this.state.currentStep === 9 && (
          <Checkout
            country={this.props.country}
            productKeyName={product}
            name={this.state.answers[0]}
            email={this.state.answers[6].email}
            phone={this.state.answers[6].phone}
            nextStep={this.startHandle}
          />
        )}
        {this.state.currentStep === 10 && (
          <ThankYou name={this.state.answers[0]} country={this.props.country} />
        )}
      </Layout>
    );
  }
}
