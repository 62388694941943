import React from "react";
import { Link } from "react-router-dom";
import styles from "./NoCandidateLanding.module.css";
import rocket from "../../assets/images/webp/yes-moons.webp";

export const NoCandidateLanding = params => {
  const name = params.name.split(" ");
  return (
    <div className={`${styles.degraded} ${styles.containerFlex}`}>
      <div className={styles.mainTitle}>¡Listo!</div>
      <div className={styles.noCandidateTitle}>
        <div className={styles.title}>
          Gracias, {name[0]} Muy pronto uno de nuestro asesores se pondrá en
          contacto contigo. ¡Hasta pronto!
        </div>
        <div className={styles.emoji}>
          <img src={rocket} className={styles.img} alt="img" />
        </div>

        <div className={`${styles.subTitle} ${styles.noCandidateSubTitle}`}>
          <div>
            <Link to="/" className={styles.blue}>
              Salir
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
