export const getCountry = () => {
  let country = window.location.href;
  country = "https://mymoons.co";
  if (country.includes("mymoons.mx")) {
    document.documentElement.lang = "es-mx";
    return "México";
  } else if (country.includes("mymoons.co")) {
    document.documentElement.lang = "es-co";
    return "Colombia";
  } else return "México";
};

export const getUtcs = () => {
  const content = JSON.parse(window.localStorage.getItem("utm_content"));

  const utcs = {
    utm_source: JSON.parse(window.localStorage.getItem("utm_source"))[0],
    utm_campaign: JSON.parse(window.localStorage.getItem("utm_campaign"))[0],
    utm_medium: JSON.parse(window.localStorage.getItem("utm_medium"))[0],
    creative: JSON.parse(window.localStorage.getItem("creative"))[0],
    content: content.pop(),
  };

  if (utcs.creative === null) {
    utcs.creative = "organico";
  }

  return utcs;
};
